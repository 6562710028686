/* DisplayClasses.css */

.display-classes {
  padding: 20px;
  background-color: rgb(211, 225, 227);
  border-radius: 8px;
  max-width: 900px;
  margin: 20px auto;
}

h2 {
  text-align: center;
  color: #000000;
  background-color: #ffffff;
  border-radius: 8px;
}

.classes-list {
  list-style-type: none;
  padding: 0;
}

.class-item {
  background-color: aliceblue;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.class-info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f1f1f1;
  border: 1px solid #b0b0b0;
  border-radius: 8px;
  margin-bottom: 15px;
}

.class-info-item {
  flex: 1;
  text-align: center;
  color: #0054af;
  font-weight: bold;
  font-size: large;
  margin: 5px 0 5px 0;
}

.class-details-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.details-section,
.notes-section {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 5px 20px 5px 20px;
  border-radius: 8px;
  flex: 1;
  text-align: center;
}

.students-section {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 0px 5px;
  border-radius: 8px;
  flex: 1;
  text-align: center;
}

.details-section strong,
.students-section strong {
  color: #333;
  font-weight: bold;
}
.expandable-title {
  cursor: pointer;
  user-select: none;
}

.notes-section ul {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  background-color: #ffffff;
  padding: 5px 20px 5px 20px;
  border-radius: 8px;
  text-align: left;
  list-style-type: none;
}

.students-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.students-section li {
  color: #555;
}

.edit-class-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.edit-class-button:hover {
  background-color: #0056b3;
}

.edit-class-form {
  font-size: 16px;
}

.edit-class-initial-fields {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 0px;
  column-gap: 20px;
  justify-content: space-between;
}

.edit-form-links {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 0px;
  column-gap: 0px;
  justify-content: space-between;
}

.add-update-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 0px;
  column-gap: 0px;
  justify-content: space-between;
}

.students-remove-class {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 0px;
  column-gap: 0px;
  justify-content: space-between;
  margin: 15px 0;
}

.filter-title {
  width: 20%;
  font-size: 18px;
  font-weight: bold;
  /* margin: 10px 0; */
  align-content: center;
}

.filter-section {
  border-radius: 8px;
  margin-bottom: 10px;
  /* padding-right: 25px; */
  padding-top: 10px;
}

.filter-item {
  margin-bottom: 0;
  align-content: center;
}

.form-group-display-class-links {
  width: 49%;
  display: flex;
  justify-content: space-evenly;
}

.form-control-display-class-link {
  width: 70%;
}

.form-control-display-class {
  width: 100%;
  padding: 10px 0px 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group-display-class {
  width: 20%;
}

.form-group-students {
  margin-bottom: 15px;
  width: 50%;
}

.form-group-display-class label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group-display-class input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn {
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-primary {
  background-color: #28a745;
  color: #fff;
}

.btn-primary:hover {
  background-color: #218838;
}

.btn-secondary {
  background-color: #855d5d;
  color: #fff;
}

.btn-secondary:hover {
  background-color: #743d3d;
}

.remove-class-button.btn-secondary {
  background-color: #8c0000;
  color: #fff;
}

.remove-class-button.btn-secondary:hover {
  text-decoration: underline;
}
.add-update-button {
  width: 15%;
}

.edit-icon-button {
  background-color: #007bff;
  border: none;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: auto;
  padding: 8px;
  margin: 5px;
}

.edit-icon-button:hover {
  background-color: #0056b3;
}

.edit-icon {
  font-size: 13px;
}

textarea {
  resize: vertical;
  min-height: 30px; /* Minimum height to make resizing visible */
  margin-left: 3%;
  width: 82%; /* Full width to make the resize handle visible */
  padding: 10px; /* Add padding for better readability */
  font-size: 16px; /* Increase font size for usability */
  box-sizing: border-box; /* Ensures the width includes padding */
  border: 1px solid #ccc; /* Set a border to make it more visible */
  border-radius: 4px; /* Rounded edges */
}
