/* Modal styles */
.student-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .student-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
  }
  
  .student-modal-content h2 {
    margin-top: 0;
  }
  
  .close-modal {
    margin-top: 20px;
    background-color: #ff4444;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .close-modal:hover {
    background-color: #cc0000;
  }