
.report-card-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Modal content styles */
  .report-card-modal {
    position: relative;
    background: #c1d9ff;
    padding: 20px;
    border-radius: 8px;
    max-width: 1000px;
    width: 90%;
    max-height: 90vh; /* Limit modal height to 90% of the viewport height */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  }

  .create-report-card-form {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .report-header {
    margin-bottom: 20px;
  }
  
  .report-section {
    margin-top: 20px;
  }
  
  .report-row {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .report-row:last-child {
    border-bottom: none;
  }
  
  .goal-name {
    flex: 1.2;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .goal-select {
    flex: 1;
    margin-right: 10px;
    padding: 5px;
    margin-bottom: 0px;
  }
  
  .expected-grade {
    flex: 1;
    margin-right: 20px;
    font-style: italic;
  }
  
  .comments-textarea {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-left: 0px;
  }
  
  .btn {
    margin-top: 20px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .create-report-card-form h2 {
    border-style: solid;
    border-color: #999999;
    border-width: 1px;
    background-color: aliceblue;
  }

  .create-report-card-form h3 {
    padding:3px;
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    border-color: rgb(195, 202, 208);
    text-align: center;
    background-color: aliceblue;
  }

  .reference-section {
    margin-bottom: 20px;
  }
  
  .grading-reference {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .grading-reference h4 {
    margin-top: 10px;
  }
  
  .grading-reference ul {
    list-style-type: none;
    padding: 0;
  }
  
  .grading-reference ul li {
    margin: 5px 0;
  }
  