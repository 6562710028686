/* src/components/SeasonManagement.css */

.season-management {
  max-width: 600px;
  margin: 0px auto;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.season-management h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.season-section {
  margin-bottom: 25px;
}

.season-section h3 {
  color: #444;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}

.input-group {
  display: flex;
  align-items: center;
}

.season-input {
  flex: 1;
  padding: 8px 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.settings-btn {
  padding: 8px 16px;
  background: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s ease;
}

.settings-btn:hover {
  background: #0056b3;
}

.settings-btn.btn-secondary {
  background: #6c757d;
}

.settings-btn.btn-secondary:hover {
  background: #5a6268;
}

.season-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.season-list li {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}

.season-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-section {
  display: flex;
  align-items: center;
}

.edit-input {
  max-width: 200px;
  margin-right: 10px;
}
