/* src/components/StudentSearch.css */

.student-search-container {
  max-width: 60%;
  margin: 25px auto 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.student-search {
  text-align: center;
  color: #333;
  margin-bottom: 15px;
}

.student-search-container input[type="text"] {
  width: 100%;
  padding: 10px 0px 10px 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.student-search-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.course-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.download-report-button {
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.download-report-button:hover {
  background-color: #0056b3;
}

.download-report-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  font-weight: bold;
  text-align: center;
}

.student-view-info h3 {
  margin-bottom: 10px;
  color: #333;
}

.next-recommended {
  /* font-weight: bold; */
  margin-bottom: 0px;
}

.courses-taken,
.student-comments,
.instructor-comments {
  margin-top: 20px;
}

.courses-taken ul,
.student-comments ul,
.instructor-comments ul {
  list-style-type: none;
  padding-left: 0;
}

.courses-taken li,
.student-comments li,
.instructor-comments li {
  background-color: #f1f1f1;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
}

.courses-taken li strong,
.student-comments li strong,
.instructor-comments li strong {
  color: #333;
}
