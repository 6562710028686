.notes-section {
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  border-radius: 4px;
}

.expandable-title {
  display: block;
  cursor: pointer;
  font-size: 18px;
  margin-bottom: 10px;
}

.add-note-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.add-note {
  cursor: pointer;
  color: #007bff;
  font-weight: bold;
}

.add-note:hover {
  color: #0056b3;
}
