/* Container styling */
.manage-report-cards-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  /* Header styling */
  .manage-report-cards-header {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
/* Filter section styling */
.filter-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  /* Report count message */
  .report-count {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-right: auto; /* Push the message to the left */
    margin-left: 50px;
  }
  
  .filter-section label {
    font-size: 14px;
    margin-right: 10px;
    color: #333;
  }
  
  .filter-section select {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
  }
  
  /* Reports list styling */
  .report-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
  }
  
  .report-list li {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 285px;
  }
  
  .report-list li:hover {
    background-color: rgb(178, 236, 255);
    cursor: pointer;
  }

  .report-list li strong {
    color: #333;
  }
  
  .report-list hr {
    border: 0;
    height: 1px;
    background: #eee;
    margin: 10px 0;
  }
  
  /* Loading and error messages */
  .loading, .error-message {
    text-align: center;
    font-size: 16px;
    color: #666;
    margin-top: 20px;
  }
  
  /* No reports message */
  .no-reports {
    text-align: center;
    font-size: 18px;
    color: #999;
    margin-top: 20px;
  }
  
  /* Responsive design */
  @media (max-width: 600px) {
    .filter-section {
      flex-direction: column;
      align-items: stretch;
      gap: 10px;
    }
  
    .report-list li {
      padding: 10px;
    }
  }
  

