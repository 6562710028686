.student-search-results {
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
}

.student-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #eee;
}

.student-row:nth-child(even) {
  background: #f9f9f9;
}

.student-row:last-child {
  border-bottom: none;
}

.student-info {
  display: flex;
  flex-direction: column;
}

.student-full-name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.student-number {
  font-size: 14px;
  color: #666;
}

.view-button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #007bff;
  transition: color 0.2s ease-in-out;
}

.view-button:hover {
  color: #0056b3;
}
