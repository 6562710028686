/* src/components/CourseList.css */

/* Media queries for responsive course list width */
.course-list {
  padding: 20px;
  width: 60%;
  margin: 20px auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

@media (max-width: 1600px) {
  .course-list {
    width: 80%;
  }
}



table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  th, td {
    border: 1px solid #bcbcbc;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f4f4f4;
  }
  
  tr {
    transition: background-color 0.3s;
    user-select: none; /* Prevent text selection */
  }
  

  
  .draggable {
    transition: background-color 0.3s;
  }
  
  .draggable:focus {
    outline: none;
  }
  
  .draggable:hover {
    border: 1px solid #000000;
  }
  
  .dragging {
    background-color: #d0e0e0; /* Highlight color when dragging */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .active-course {
    background-color: #dcedfa;  /* Light green for active */
  }
  
  .inactive-course {
    background-color: #fccfcf;  /* Light red for inactive */
  }