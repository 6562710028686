/* src/components/UserList.css */

.user-list-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.user-list-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.user-list-container .sort-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.user-list-container .sort-controls label {
  margin-right: 10px;
  font-weight: bold;
}

.user-list-container .sort-controls select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.user-list-container .pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.user-list-container .pagination-button {
  padding: 10px;
  margin: 0 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.user-list-container .pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.user-list-container .pagination-info {
  font-size: 16px;
  font-weight: bold;
}

.user-list-container table {
  width: 100%;
  border-collapse: collapse;
}

.user-list-container table th,
.user-list-container table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
  font-size: 16px;
}

.user-list-container table th {
  background-color: #f1f1f1;
  font-weight: bold;
  color: #333;
}

.user-list-container table tr:nth-child(even) {
  background-color: #f9f9f9;
}
