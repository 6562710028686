/* src/components/Register.css */

.register-container {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 50px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .register-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .register-form {
    display: flex;
    flex-direction: column;
  }
  
  .register-field {
    margin-bottom: 15px;
  }
  
  .register-label {
    font-weight: bold;
    color: #555;
    margin-bottom: 5px;
    display: block;
  }
  
  .register-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .register-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .register-button:hover {
    background-color: #0056b3;
  }
  
  .register-error-message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
  
  .register-success-message {
    color: green;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }