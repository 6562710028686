.report-heading {
    padding: 20px 0;
    margin: 15px 0;
    text-align: center;
    border-bottom: 1px solid gainsboro;
}
.report-details p {
    margin-bottom: 10px;
    border-right: 1px solid gainsboro;
}
.report-details p:nth-child(3n) {
    border-right: 0;
}
