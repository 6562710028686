
  /* General container styles */
  .container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Default styling for headings */
  h1, h2, h3, h4, h5, h6 {
    color: #333;
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  /* Default styling for buttons */
  button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
  }
  
  button:hover {
    opacity: 0.9;
  }
  
.btn{
  margin-top: 0px;
}

  .btn-primary {
    background-color: #007bff;
    color: #fff;
  }
  
  .btn-secondary {
    background-color: #3c9547;
    color: #fff;
  }
  
  .btn-danger {
    background-color: #dc3545;
    color: #fff;
  }
  
  /* Input fields */
  .create-report-card-template input[type="text"], .create-report-card-template input[type="date"], .create-report-card-template textarea, select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  textarea {
    resize: vertical;
  }
  
  /* Form group styling */
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  /* List styles */
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  
  /* Card/Section styles */
  .card, .section {
    background-color: #fff;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  /* Flex utilities */
  .flex {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .flex-column {
    flex-direction: column;
  }
  
  .flex-space-between {
    justify-content: space-between;
  }
  
  /* Default link styling */
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* Toastify custom styling */
  .Toastify__toast {
    font-size: 14px;
  }
  
  /* Specific styling for CreateReportCardTemplate component */
  .create-report-card-template {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    max-width:1600px;
    margin: 20px auto;
  }
  
  .create-report-card-template h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .learning-goals {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .learning-goal {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
    background-color: #f9f9f9;
    width:200px;
  }
  
  .learning-goal input, .learning-goal select {
    margin-bottom: 10px;
  }
  
  .add-learning-goal-button {
    margin-bottom: 20px;
  }
  
  .remove-learning-goal-button {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .remove-learning-goal-button:hover {
    background-color: #b52a3a;
  }
  
  .course-select {
    margin-bottom: 20px;
  }
  
  .save-button {
    background-color: #28a745;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .save-button:hover {
    background-color: #218838;
  }
  
  textarea {
    resize: vertical;
    min-height: 100px;
  }
  