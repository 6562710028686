.customer-form-container {
  .customer-form-heading {
    text-transform: uppercase;
    text-align: center;
    margin: 20px 0;
    font-weight: bold;
    font-size: 18px;
  }

  .form-group {
    align-items: flex-start;
    margin: 10px 0;

    input,
    input[type="text"],
    textarea {
      border-radius: 10px;
      border: 1px solid lightgray;
    }

    input[type="tel"],
    input[type="email"] {
      width: 100%;
    }

    label {
      padding-left: 5px;
    }
  }

  .form-heading {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;
    padding-left: 5px;
    text-align: center;
  }

  .student-section {
    background-color: rgb(191, 212, 229);
    padding: 5px;
    padding-bottom: 10px;
    border-radius: 10px;
    margin-bottom: 15px;
  }

  .remove-student-button {
    align-items: self-end;
  }
}
