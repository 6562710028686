/* src/components/CustomerView.css */
.customer-view-wrapper {
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px;
  /* position: relative; */
}

.customer-view-back-button {
  position: absolute;
  top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: left;
}

.customer-view-edit-button {
  position: absolute;
  top: 10px;
  right: 40px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: right;
}

.customer-view-back-button:hover,
.customer-view-edit-button:hover {
  background-color: #0056b3;
}

.customer-view-container {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.customer-info,
.student-info {
  width: 48%;
}

.student-details div {
  padding: 0px;
}

.customer-info h2,
.student-info h2 {
  margin-bottom: 20px;
  color: #333;
}

.customer-info p,
.student-info p {
  margin: 8px 0;
  font-size: 16px;
  color: #555;
}

.customer-info strong,
.student-info strong {
  color: #333;
}
