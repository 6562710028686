.customer-list-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 20px;;

    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #ebebeb;
  }
  .loading {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 20px;
    font-size: large;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #ebebeb;
    height: 1300px;
  }
  
  .customer-list-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .customer-list-table th,
  .customer-list-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .customer-list-table th {
    background-color: #f4f4f4;
  }
  
  .customer-list-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .customer-list-pagination .page-number {
    background: none; /* Remove background */
    border: none; /* Remove border */
    color: inherit; /* Use inherited text color */
    padding: 5px; /* Add spacing if needed */
    cursor: pointer; /* Keep pointer for clickable buttons */
  }
  
  .customer-list-pagination .page-number:hover {
    text-decoration: underline; /* Optional: Add hover effect */
  }
  
  .customer-list-pagination .active-page {
    padding: 5px;
    font-weight: bold;
    background-color: #007bff; /* Example active background color */
    color: white; /* Active text color */
    pointer-events: none; /* Disable clicks */
    cursor: default; /* Regular cursor for non-clickable button */
    border-radius: 10px; /* Optional: Rounded corners for active page */
  }
  .next-button {
    margin-left: 8%;
    padding: 5px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .previous-button{
    margin-right: 8%;
    padding: 5px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .customer-list-pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .customer-list-select-button, .customer-list-edit-button{
    padding: 8px 12px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .customer-list-edit-button{
    margin-left: 5px;
  }
  
  .add-customer{
    display: block !important;
    margin-left: auto !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    background-color: #28a745 !important;
  }

