.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal content styles */
.reusable-modal {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 1200px !important;
  width: 90%;
  max-height: 90vh; /* Limit modal height to 90% of the viewport height */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}
