.student-search-container {
  max-width: 60%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.customer-item {
  margin-bottom: 5px;
}

.action-icon {
  cursor: pointer;
  margin-right: 10px;
}

.action-icon:hover {
  opacity: 0.7;
}
