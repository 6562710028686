.customer-search-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;

  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ebebeb;
}

.customer-search-button {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
}

.customer-search-container .row {
  justify-content: space-evenly;
}
.customer-search-container .customer-search-input {
  border-radius: 4px;
  border-width: 1px;
  width: 30%;
  border-color: #ddd;
}
