/* src/components/Login.css */

.login-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-title {
    text-align: center;
    color: #333;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
  }
  
  .login-field {
    margin-bottom: 15px;
  }
  
  .login-label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .login-input {
    width: calc(100% - 10px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .login-button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  .login-error-message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
  
  .register-button {
    padding: 10px;
    background-color: #00bc10;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
  }
  
  .register-button:hover {
    background-color: #006b19;
  }