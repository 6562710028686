.create-class-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-group-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .large-small .form-group:first-child {
    flex: 0 0 70%; /* Instructor field takes 70% width */
  }
  
  .large-small .form-group:last-child {
    flex: 0 0 30%; /* Class Code field takes 30% width */
  }
  
  .form-group-row .form-group {
    flex: 1;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  input[type="checkbox"] {
    margin-left: 10px;
    transform: scale(1.2); /* Larger checkbox */
  }
  
  input[type="date"] {
    font-family: inherit;
    font-size: 16px;
    padding: 10px;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }

  .form-group-students {
    ul {
      padding-left: 10px;

      li {
        margin: 10px 0;
      }
    }
  }

  .student-heading {
    font-size: 18px;
    font-weight: bold;
    padding-left: 10px;
  }
  
  .add-student-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0 6px;
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 50%;
  }

  .remove-student-button {
    background-color: #8c0000;
    color: white;
    border: none;
    padding: 5px 10px;
    margin-left: 5px;
    cursor: pointer;
    border-radius: 10px;
  }
  
  .add-student-button:hover {
    background-color: #0056b3;
  }
  
  /* Modal styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .close-modal {
    margin-top: 20px;
    background-color: #ff4444;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .close-modal:hover {
    background-color: #cc0000;
  }
  