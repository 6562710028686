.students-table {
  width: 100%;
  border-collapse: collapse;
}

.students-table td {
  border: 1px solid #ddd;
  border-left-width: 0px;
  border-right-width: 0px;
  padding: 2px 10px;
  text-align: center;
}

.students-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  border: 0px solid #ddd;
  padding: 0px 10px 5px 10px;
  text-align: center;
}

.action-icon {
  cursor: pointer;
  margin-right: 10px;
}

.action-icon:hover {
  opacity: 0.7;
}

.checkmark-icon {
  margin-right: 10px;
}
