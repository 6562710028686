/* src/components/Header.css */
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
  }
  
  .logo img {
    height: 40px;
  }
  
  .welcome-message {
    font-size: 18px;
  }
  
  .nav-links {
    display: flex;
    gap: 15px;
  }
  
  .nav-links a {
    color: #fff;
    text-decoration: none;
  }
  
  .nav-links a:hover {
    text-decoration: underline;
  }
  
  .logout-link {
    cursor: pointer;
  }
  
  