/* MidSeasonReportForm Component Styling */

.mid-season-report-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 20px auto;
  }
  
  .mid-season-report-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    align-items: start;
  }
  
  .form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .form-group select,
  .form-group textarea,
  .form-group input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s ease;
    margin-left: 0px;
  }
  
  .form-group select:focus,
  .form-group textarea:focus,
  .form-group input[type="text"]:focus {
    border-color: #007bff;
    outline: none;
  }
  
  textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .submit-button {
    width: 100%;
    background-color: #28a745;
    color: #fff;
    padding: 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #218838;
  }
  
  .mid-season-comment {
    margin-top: 20px;
  }
  
  /* Specific styling for options */
  .option-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .dropdown {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    font-size: 16px;
  }
  
  /* Mobile-friendly adjustments */
  @media (max-width: 768px) {
    .mid-season-report-container {
      padding: 15px;
      max-width: 100%;
    }
  
    .submit-button {
      font-size: 16px;
      padding: 12px;
    }
  }
  