/* src/components/General/Settings.css */

.settings {
  max-width: 1000px;
  margin: 30px auto;
  padding: 20px;
  background: #d1d1d1;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.settings h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.settings-menu ul {
  display: flex;
  justify-content: center;
  gap: 10px;
  list-style: none;
  padding: 0;
}

/* .settings-menu li {
  margin-bottom: 15px;
} */

.settings-menu button {
  width: 100%;
  padding: 10px;
  background: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-bottom: 10px;
}

.settings-menu button:hover {
  background: #0056b3;
}

.selected-setting .back-button {
  margin-bottom: 10px;
  padding: 8px 16px;
  background: #6c757d;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s ease;
}

.selected-setting .back-button:hover {
  background: #5a6268;
}
