/* CreateGeneralReportTemplate.css */

.create-general-report-template {
    padding: 20px;
    max-width: 600px;
    margin: 20px auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .form-group label {
    flex: 1;
    font-weight: bold;
    color: #555;
    margin-right: 10px;
  }
  
  .form-group input {
    flex: 2;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .section {
    margin-bottom: 15px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .btn {
    padding: 8px 12px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .add-section-btn, .add-option-btn {
    background-color: #28a745;
    color: #fff;
    margin-top: 10px;
  }
  
  .add-section-btn:hover, .add-option-btn:hover {
    background-color: #218838;
  }
  
  .remove-section-btn, .remove-option-btn {
    background-color: #dc3545;
    color: #fff;
    margin-left: auto;
  }
  
  .remove-section-btn:hover, .remove-option-btn:hover {
    background-color: #c82333;
  }
  
  .form-actions {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .btn {
    width: auto;
  }

  .btn-primary {
    background-color: #007bff;
    color: #fff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .btn-secondary {
    background-color: #6c757d;
    color: #fff;
  }
  
  .btn-secondary:hover {
    background-color: #5a6268;
  }